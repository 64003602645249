* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.snapContainer {
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	width: 100%;
	-webkit-overflow-scrolling: touch;
}

.snapChild {
	scroll-snap-align: start;
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	flex-direction: column;
	position: relative;
}

#helpIcon {
	top: 94%;
	left: 96%;
	position: absolute;
	z-index: 1;
}

#nameContainer {
	top: 43%;
	left: 10%;
	justify-content: center;
	align-items: center;
	padding: 0vw 4vw 0vw 4vw;
	position: absolute;
	z-index: 1;
}

#backgroundSVG {
	position: relative;
	z-index: 0;
	background-color: rgb(0, 0, 0);
	width: 100vw;
	height: 100vh;
}

#devInfo {
	background-color: #fdf3d1;
}

#soundInfo {
	background-color: #effff8;
}

#mobileName {
	background-color: #eff3ff;
}

.shortInput {
	width: '10vw';
}

@media (max-width: 600) {
}
